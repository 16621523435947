import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getAllEntities as getAllEmployees } from 'app/entities/employee/employee.reducer';
import { createEntity, reset, getEntity, updateEntity } from './gift.reducer';
import { useSnackbar } from 'notistack';
import useScriptRef from 'app/hooks/useScriptRef';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { FieldArray, Formik } from 'formik';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapPicker from 'react-google-map-picker';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AttachmentIcon } from 'app/components/icons';
import * as Yup from 'yup';
import { PHONE_REGEX } from 'app/config/constants';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
const DefaultLocation = { lat: 24.774265, lng: 46.738586 };
const DefaultZoom = 6;

export const GiftUpdate = ({ open, setOpen, id }) => {
  const dispatch = useAppDispatch();
  const scriptedRef = useScriptRef();
  const { enqueueSnackbar } = useSnackbar();
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [Glocation, setGLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const handleChangeLocation = (lat: number, lng: number) => {
    setGLocation({ lat, lng });
  };
  const handleChangeZoom = newZoom => {
    setZoom(newZoom);
  };

  const navigate = useNavigate();
  const allEmployees = useAppSelector(state => state.employee.allEntities);
  const isNew = id === undefined;
  const giftEntity = useAppSelector(state => state.gift.entity);

  const loading = useAppSelector(state => state.gift.loading);
  const updating = useAppSelector(state => state.gift.updating);
  const updateSuccess = useAppSelector(state => state.gift.updateSuccess);
  const [locationModalOpen, setLocationModalOpen] = React.useState(false);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const handleClose = () => {
    setOpen(false);
    navigate('/giftcards' + location.search);
  };

  useEffect(() => {
    if (!isNew) {
      dispatch(getEntity(id));
    }
    dispatch(getAllEmployees());
  }, [open]);

  useEffect(() => {
    if (updateSuccess && open) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    const entity = {
      ...values,
    };
    const formData: any = new FormData();
    if (!entity.image?.filePath && entity.image) formData.append(`image`, entity.image);
    if (entity.details) formData.append(`details`, entity.details);
    if (entity.user?.id) formData.append(`userId`, entity.user?.id);
    if (!isNew && entity.contactId) formData.append('contact.id', entity.contactId);
    if (entity.cFirstName) formData.append(`contact.firstName`, entity.cFirstName);
    if (entity.cLastName) formData.append(`contact.lastName`, entity.cLastName);
    if (entity.cEmail) formData.append(`contact.email`, entity.cEmail);
    if (entity.cPhoneNumber) formData.append(`contact.phoneNumber`, entity.cPhoneNumber);
    if (!entity.attachment?.filePath && entity.attachment) formData.append(`attachment`, entity.attachment);
    if (entity.expirationDate) formData.append(`expirationDate`, moment(entity.expirationDate).format('YYYY-MM-DD'));
    values.addresses.map((location: any, index: number) => {
      if (location.id) formData.append(`addresses[${index}].id`, location.id);
      formData.append(`addresses[${index}].longitude`, location.longitude);
      formData.append(`addresses[${index}].latitude`, location.latitude);
      formData.append(`addresses[${index}].generateQR`, false);
    });

    if (isNew) {
      const {
        meta: { requestStatus },
      } = await dispatch(createEntity(formData));
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.gift.createSuccess'), { variant: 'success' });
      }
    } else {
      const {
        meta: { requestStatus },
      } = await dispatch(
        updateEntity({
          id: entity.id,
          values: formData,
        })
      );
      if (requestStatus === 'fulfilled') {
        enqueueSnackbar(translate('kafaatApp.gift.updateSuccess'), { variant: 'success' });
      }
      return requestStatus;
    }
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" color={'secondary'}>
        {isNew ? (
          <Translate contentKey="kafaatApp.gift.home.createLabel">Create new Gift</Translate>
        ) : (
          <Translate contentKey="kafaatApp.gift.home.editLabel">Edit Gift</Translate>
        )}
      </DialogTitle>
      <Divider />
      {(isNew || (!isNew && !loading)) && (
        <Formik
          initialValues={
            isNew
              ? {
                  image: undefined,
                  details: undefined,
                  user: undefined,
                  attachment: undefined,
                  expirationDate: null,
                  cFirstName: undefined,
                  cLastName: undefined,
                  cPhoneNumber: undefined,
                  cEmail: undefined,
                  addresses: [],
                }
              : {
                  ...giftEntity,
                  expirationDate: giftEntity.expirationDate ? moment(giftEntity.expirationDate) : null,
                  cFirstName: giftEntity.contact?.firstName ? giftEntity.contact?.firstName : undefined,
                  cLastName: giftEntity.contact?.lastName ? giftEntity.contact?.lastName : undefined,
                  cPhoneNumber: giftEntity.contact?.phoneNumber ? giftEntity.contact?.phoneNumber : undefined,
                  cEmail: giftEntity.contact?.email ? giftEntity.contact?.email : undefined,
                  contactId: giftEntity.contact?.id,
                }
          }
          validationSchema={Yup.object().shape({
            image: Yup.mixed().nullable().required(translate('error.form.required')),
            details: Yup.string().required(translate('error.form.required')),
            user: Yup.mixed().required(translate('error.form.required')),
            attachment: Yup.mixed().nullable(),
            expirationDate: Yup.string().nullable().required(translate('error.form.required')),
            cFirstName: Yup.string().max(255),
            cLastName: Yup.string().max(255),
            cPhoneNumber: Yup.string().trim().matches(PHONE_REGEX, translate('error.form.invalidPhone')),
            cEmail: Yup.string().email(translate('error.form.invalidEmail')),
            addresses: Yup.array().nullable(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const requestStatus = await saveEntity(values);

              if (scriptedRef.current && requestStatus === 'fulfilled') {
                setStatus({ success: true });
                setSubmitting(false);
                handleClose();
              }
            } catch (err) {
              console.error(err);
              if (scriptedRef.current) {
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.image && errors.image)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.gift.image">image </Translate>
                      </FormLabel>
                      <FilledInput
                        type={'text'}
                        value={values.image ? (values.image.name ? values.image.name : values.image.fileName) : ''}
                        endAdornment={
                          <IconButton component="label">
                            <AttachmentIcon />
                            <input
                              type="file"
                              accept={'image/*'}
                              hidden
                              name={'image'}
                              onChange={event => {
                                setFieldValue('image', event.currentTarget.files[0]);
                              }}
                              onBlur={handleBlur}
                              id="fileupload"
                            />
                          </IconButton>
                        }
                      />
                      {touched.image && errors.image && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.image.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" variant="filled" error={Boolean(touched.user && errors.user)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.gift.employee">Employee </Translate>
                      </FormLabel>
                      <Autocomplete
                        onBlur={handleBlur}
                        inputMode={'text'}
                        value={values.user}
                        onChange={(e, value) => setFieldValue('user', value)}
                        getOptionLabel={(option: any) => {
                          const company = option.company
                            ? `- ${currentLocale === 'ar-ly' ? option.company?.name : option.company?.nameEn}`
                            : '';
                          return `${option.firstName} ${option.lastName} ${company}`;
                        }}
                        renderInput={params => <TextField error={Boolean(touched.user && errors.user)} {...params} variant={'filled'} />}
                        options={allEmployees}
                      />
                      {touched.user && errors.user && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.user.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.details && errors.details)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.gift.details">details </Translate>
                      </FormLabel>
                      <FilledInput
                        multiline
                        rows={3}
                        type={'text'}
                        name="details"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.details}
                      />
                      {touched.details && errors.details && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.details.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <br /> <br />
                <Typography color="secondary" variant="subtitle1" gutterBottom>
                  <Translate contentKey="kafaatApp.gift.addresses">Addresses </Translate>
                </Typography>
                <FieldArray name="addresses">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.addresses?.length > 0 &&
                        values.addresses.map((location, index) => (
                          <div key={index}>
                            <Grid container spacing={2}>
                              <Grid item>
                                <FormControl size="small" margin="normal">
                                  <FormLabel>
                                    <Translate contentKey="kafaatApp.gift.address">Address </Translate>
                                  </FormLabel>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      if (values.addresses[index].latitude)
                                        setDefaultLocation({
                                          lat: parseFloat(values.addresses[index].latitude),
                                          lng: parseFloat(values.addresses[index].longitude),
                                        });
                                      else setDefaultLocation(DefaultLocation);
                                      setLocationModalOpen(true);
                                    }}
                                    startIcon={<LocationOnIcon />}
                                  >
                                    <Translate contentKey="kafaatApp.business.locationOnMap">Location on Map</Translate>
                                  </Button>
                                  <Dialog open={locationModalOpen} onClose={() => setLocationModalOpen(false)}>
                                    <DialogContent>
                                      <MapPicker
                                        defaultLocation={defaultLocation}
                                        zoom={zoom}
                                        style={{ height: '600px', width: '500px' }}
                                        onChangeLocation={handleChangeLocation}
                                        onChangeZoom={handleChangeZoom}
                                        apiKey="AIzaSyDbThmL-zw_rk-mWcSGVnRgm1y-zaiwgIA"
                                      />
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        size={'large'}
                                        onClick={() => setLocationModalOpen(false)}
                                        variant="outlined"
                                        color={'secondary'}
                                      >
                                        <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                                      </Button>
                                      <Button
                                        size={'large'}
                                        onClick={() => {
                                          setFieldValue(`addresses.${index}.longitude`, Glocation.lng);
                                          setFieldValue(`addresses.${index}.latitude`, Glocation.lat);
                                          setLocationModalOpen(false);
                                        }}
                                        color="primary"
                                        variant="contained"
                                      >
                                        <Translate contentKey="kafaatApp.business.selectLocation">Select Location </Translate>
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                      <Button variant="contained" startIcon={<AddIcon />} onClick={() => push({})}>
                        <Translate contentKey="kafaatApp.gift.newAddress">New address </Translate>
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.expirationDate && errors.expirationDate)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.expiryDate">Expiry Date </Translate>
                      </FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={values.expirationDate}
                          inputFormat={'YYYY-MM-DD'}
                          onChange={event => {
                            setFieldValue('expirationDate', event ? event.$d : null);
                          }}
                          renderInput={params => <TextField {...params} variant={'filled'} value={values.expirationDate} />}
                        />
                      </LocalizationProvider>
                      {touched.expirationDate && errors.expirationDate && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.expirationDate.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.attachment && errors.attachment)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.gift.attachment">Attachment </Translate>
                      </FormLabel>
                      <FilledInput
                        type={'text'}
                        value={values.attachment ? (values.attachment.name ? values.attachment.name : values.attachment.fileName) : ''}
                        endAdornment={
                          <IconButton component="label">
                            <AttachmentIcon />
                            <input
                              type="file"
                              accept={'image/*'}
                              hidden
                              name={'attachment'}
                              onChange={event => {
                                setFieldValue('attachment', event.currentTarget.files[0]);
                              }}
                              onBlur={handleBlur}
                              id="fileupload"
                            />
                          </IconButton>
                        }
                      />
                      {touched.attachment && errors.attachment && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.attachment.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <br /> <br />
                <Typography color="secondary" variant="subtitle1" gutterBottom>
                  <Translate contentKey="kafaatApp.business.offer.contactPerson">Contact Person </Translate>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.cFirstName && errors.cFirstName)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.firstName">First Name </Translate>
                      </FormLabel>
                      <FilledInput
                        id="offer-firstName"
                        type={'text'}
                        name="cFirstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cFirstName}
                      />
                      {touched.cFirstName && errors.cFirstName && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.cFirstName.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.cLastName && errors.cLastName)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.business.offer.lastName">Last Name </Translate>
                      </FormLabel>
                      <FilledInput
                        id="offer-lastName"
                        type={'text'}
                        name="cLastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cLastName}
                      />
                      {touched.cLastName && errors.cLastName && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.cLastName.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.cPhoneNumber && errors.cPhoneNumber)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.employee.phoneNumber">phoneNumber </Translate>
                      </FormLabel>
                      <FilledInput
                        id="offer-phoneNumber"
                        type={'text'}
                        name="cPhoneNumber"
                        value={values.cPhoneNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.cPhoneNumber && errors.cPhoneNumber && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.cPhoneNumber.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal" error={Boolean(touched.cEmail && errors.cEmail)}>
                      <FormLabel>
                        <Translate contentKey="kafaatApp.employee.email">Email </Translate>
                      </FormLabel>
                      <FilledInput
                        id="offer-email"
                        type={'text'}
                        name="cEmail"
                        value={values.cEmail}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {touched.cEmail && errors.cEmail && (
                        <FormHelperText error id="standard-weight-helper-text">
                          {errors.cEmail.toString()}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button size={'large'} onClick={handleClose} variant="outlined" color={'secondary'}>
                  <Translate contentKey="kafaatApp.business.home.cancel">Cancel</Translate>
                </Button>
                <LoadingButton size={'large'} color="primary" variant="contained" loading={isSubmitting} type={'submit'}>
                  <Translate contentKey="kafaatApp.business.home.save">Save</Translate>
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default GiftUpdate;
